<template>
  <div class="finish-wrap">
    <div class="finish-text" v-if="type == 'index'">您已提交问卷，感谢您的参与!</div>
    <div class="finish-text" v-if="type == 'survey'">问卷已提交，感谢您的参与!</div>
  </div>
</template>

<script>
export default {
  computed: {
    type: function () {
      return this.$route.query.type
    },
  },
}
</script>

<style lang="scss" scoped>
.finish-wrap {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/img/home.png');
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .finish-text {
    text-align: center;
    font-size: 20px;
    line-height: 2;
    white-space: pre-line;
    margin-top: -100px;
  }
}
</style>